<template>
  <div
    class="wrapper">
    <div
      v-if="!editMode && !isExpandedMode">
      <div
        v-if="!isBudgetPriceCol"
        v-safe-html="valueForRenderingString" />
      <div
        v-else
        class="d-flex justify-end align-end"
        style="height:100%">
        <span
          v-if="unreadOffer"
          class="unread-offers" />
        <template
          v-if="!hidePrivatePrice">
          <app-icon-btn
            :disabled="disablePrivatePrice"
            :btn-class="'mr-2 pointer '.concat(disablePrivatePrice ? 'no-access' : '')"
            bottom
            :show-tooltip="disablePrivatePrice"
            icon-height="17px"
            icon-name="mdi-currency-usd"
            icon-color="darkGrey"
            @click="disablePrivatePrice ? null : showCommentsModalTrigger({
              privateMode: 'offer',
              rowId: id,
            })" />
        </template>
        <span v-safe-html="valueForRenderingString" />
      </div>
    </div>
    <slot
      v-else
      name="inputBlock" />
  </div>
</template>
<script>
import {
  mapState, mapGetters, mapActions,
} from 'vuex';
import productHeaders from '@/constants/productHeaders';
export default {
  name: 'ProductCellCurrencyLayout',
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: () => null,
    },
    disabledEditMode: {
      type: Boolean,
      default: () => false,
    },
    valueForRendering: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    col: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['role']),
    ...mapGetters(['collaboratorGroup', 'premiumGroup']),
    ...mapGetters('ProjectDetails', ['statusOfProject']),
    ...mapGetters('Comments', ['slant']),
    valueForRenderingString() {
      return this.valueForRendering || '';
    },
    isBudgetPriceCol() {
      return this.col === productHeaders.BUDGET_PRICE;
    },
    hidePrivatePrice() {
      if (this.$route.query.version) {
        return true;
      }
      return !((this.statusOfProject === 'active'));
    },
    disablePrivatePrice() {
      return !(this.premiumGroup || this.collaboratorGroup);
    },
    unreadOffer() {
      return this.slant(this.id)?.offersUnread;
    },
  },
  methods: {
    ...mapActions('ProjectDetailsTableSchedule', ['showCommentsModalTrigger']),
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  width: auto;
}
.unread-offers {
  border-radius: 50%;
  background-color: red;
  width: 10px;
  height: 10px;
  align-self: self-start;
}
::v-deep .v-input__append-inner {
  margin-top: 0;
  align-self: center;
}
</style>
